<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-form-select v-if="xhrData.hardwareType!=1" :label="hardwareTypeLabel" required :slots="EquipmentTypeEnum" @change="equipmentChange" :bindValue="xhrData.equipmentTypeValue"></x-form-select>
        <!-- <x-form-input v-if="xhrData.hardwareType!=1" label="新建设备类型" v-model="xhrData.otherEquipmentType"></x-form-input> -->
        <x-form-label :label="xhrData.hardwareType==1?'牙椅品牌':'品牌'" required :bindValue="xhrData.brandName" @change="brandChange"></x-form-label>
        <!-- <x-form-input label="新建品牌" v-model="xhrData.otherBrandName"></x-form-input> -->
        <x-form-input label="型号" v-model="xhrData.model"></x-form-input>
        <template v-if="customerType==1000">
          <x-form-date label="安装时间" @change="installedTimeChange" :defaultDate="xhrData.installedTime"></x-form-date>
          <x-form-input label="数量" type="number" v-model="xhrData.installedCount">
            <label class="from-unit">台</label>
          </x-form-input>
        </template>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formDate from "@/components/formControl/formDate";
import formLabel from "@/components/formControl/formLabel";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import { cachePage } from "@/script/mixins/cachePage";
import { getHardware, addHardware, updateHardware, deleteHardware } from "@/api/customerCenter";
import { ajaxBack } from "@/script/mixins/ajaxBack";

export default {
  name: "customerCenterDetailHardwareInfoEdit",
  data () {
    return {
      hardwareTypeLabel: null,
      customerType: this.$route.params.customerType,
      isDisabledBtn: true,
      type: this.$route.params.type,
      xhrData: {
        customerId: this.$route.params.hardwareId,
        hardwareType: this.$route.params.hardwareType,
        equipmentType: null,
        equipmentTypeValue: null,
        otherEquipmentType: null,
        brandId: null,
        brandName: null,
        otherBrandName: null,
        model: null,
        installedTime: null,
        installedCount: null
      },
      EquipmentTypeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    document.title = "";
    this.type == "new" && (document.title = "硬件信息");
    switch (this.xhrData.hardwareType) {
      case "999":
        this.hardwareTypeLabel = "其他设备类型";
        break;
      case "2":
        this.hardwareTypeLabel = "数字化设备类型";
        break;
      case "3":
        this.hardwareTypeLabel = "器械类型";
        break;
      default:
        break;
    }
    this.setEnum();
  },
  methods: {
    installedTimeChange (item) {
      this.xhrData.installedTime = item;
    },
    equipmentChange (item) {
      this.xhrData.equipmentTypeValue = item.code;
      this.xhrData.equipmentType = item.name;
    },
    brandChange () {
      this.$router.push({
        path: "/searchPage/brand"
      });
    },
    setEnum () {
      if (this.xhrData.hardwareType != 1) {
        let configJson = null;
        const configJsonData = JSON.parse(sessionStorage.getItem("configJson"));
        switch (this.xhrData.hardwareType) {
          case "999":
            configJson = configJsonData.OtherEquipmentTypeEnum;
            break;
          case "2":
            configJson = configJsonData.DigitalEquipmentTypeEnum;
            break;
          case "3":
            configJson = configJsonData.TreatmentEquipmentTypeEnum;
            break;
          default:
            break;
        }
        Object.keys(configJson).forEach(key => {
          this.EquipmentTypeEnum[0].values.push({
            code: key,
            name: configJson[key]
          });
        });
      }
      this.type == "edit" && this.getData();
    },
    getData () {
      getHardware({
        hardwareId: this.$route.params.hardwareId
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = this.xhrData.brandName;
      });
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (this.type == "new") {
          addHardware(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "添加成功！");
          });
        } else {
          updateHardware(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "修改成功！");
          });
        }
      }
    },
    delClick () {
      deleteHardware({
        hardwareId: this.xhrData.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    },
    addressEvent () {
      this.$router.push({
        path: "/customerCenter/baseInfo/addressMap"
      });
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        if (newVal.hardwareType == 1) {
          this.isDisabledBtn = !(newVal.brandId != null);
        } else {
          this.isDisabledBtn = !(newVal.equipmentTypeValue != null && newVal.brandId != null);
        }
      },
      deep: true
    },
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.xhrData.brandId = searchValue.id;
        this.xhrData.brandName = searchValue.brandName;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [formInput.name]: formInput,
    [formBox.name]: formBox,
    [formSelect.name]: formSelect,
    [footerBox.name]: footerBox,
    [formDate.name]: formDate,
    [formLabel.name]: formLabel
  }
};
</script>

<style scoped>
.pos-address-icon {
  margin-left: 0.1rem;
  width: 0.14rem;
  height: 0.18rem;
  background: url('~@images/pos_address_icon.png') no-repeat center/0.14rem
    0.18rem;
}
</style>
